import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import settings from './settings'
import {setProp, deleteProp} from 'bh-mod'
// setProp(state,['units',data.id],data)
Vue.use(Vuex)

export default {
    state: {
        data: {}, //don't access this directly from Vue files
        getStarted: false,
        introPage:false,
        rulePage:false,
        tags:[],
        appData:{},
        rules:{},
        editingLot:{},
        editingAmenity:{},
        units:[],
        masterplanSettings:false,
        unitTypes:[],
        unitGroups:[],
        currentShape:'',
        editorSettings:null,
        modal:{
            show:false,
            index:null,
            type:'',
            settings:null,
            title:'',
            identifier:'',
            text:'',
            lot:null,
            confirm:() => {},
            load:false,
            editor:null
        },
        lotDrawer:{
            show:false,
            type:'add'
        },
        amenityDrawer: {
            show:false,
            type:'add'
        },
        newLotInfo:{},
        newAmenityInfo: {},
        lots: {},
        seenIntro: false,
        openLeftNav: {
            type:'close',
            id:'',
            item:'',
            lot:''
        },
        currentType:'',
        crumbs:'',
        children:{},
        childLots:{},
        childUnits:{},
        colorDrawer:false
    },
    mutations: {
		SET_SETTINGS(state, data) {
			// setting
		},
        COLORS_SETTINGS: (state) => {
            state.colorDrawer = true
        },
        CLOSE_COLORS: (state) => {
            state.colorDrawer = false
        },
        SET_CRUMBS:(state, data) => {
            state.crumbs = data
        },
        changeType:(state,data) => {
            state.currentType = data
        },
        SEEN_INTRO: (state) => {
            state.seenIntro = true
        },
        INTRO_PAGE: (state) => {
            state.introPage = true
        },
        CLOSE_INTRO: (state) => {
            state.introPage = false
        },
        OPEN_RULE: (state) => {
            state.rulePage = true
        },
        CLOSE_RULE: (state) => {
            state.rulePage = false
        },
        REORDER_LOTS: (state, ids) => {
            ids.forEach((id,index) => {
                state.lots[id].order = index
            })
        },
        APPLY_RULES: (state,rules) => {
            setProp(state,['rules'],rules)
        },
        SITEPLAN_RULES: (state) => {
            if (!Object.keys(state.rules).length) state.masterplanRulesModal = true
            else state.masterplanRulesDrawer = true
        },
        SITEPLAN_IMAGE_SETTINGS: (state) => {
            console.log('OPENING SITEPLAN SETTINGS')
            state.masterplanSettings = true
        },
        CLOSE_SETTINGS: (state) => {
            state.masterplanSettings = false
            state.masterplanRulesDrawer = false
            state.masterplanRulesModal = false
        },
        SET_APPDATA_ONLY: (state,data) => {
            console.log('SET_APPDATA_ONLY => ', data)
            setProp(state,['appData'],data)
        },
        DELETE_SPOT: (state,id) => {
            let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(id))
            state.editorSettings.settings.spots.splice(index,1)
            state.editorSettings.self.redraw()
        },
        SET_EDITOR_SETTINGS: (state,obj) => {
            state.editorSettings = obj
        },
        OPEN_LEFTNAV: (state, id) => {
            console.log('aaaaaashape id', id)
            let found = Object.values(state.lots).find(x => x.shapeId == id)
            if (typeof found == 'object') {

                if (found.inherit && found.inherit != '') {
                    let inherit = found.inherit
                    if (inherit.id) inherit = inherit.id
                    if (state.childLots[inherit] && state.childLots[inherit].name) state.crumbs = state.childLots[inherit].name
                }
                else if (found.name && found.name != '')state.crumbs = found.name

                if (found.type == 'lot') {
                    state.openLeftNav = {
                        type:'open',
                        id:Date.now(),
                        item:'Master Plan Lots',
                        lot:found.id
                    }
                    state.currentType = 'lots'
                } else {
                    state.openLeftNav = {
                        type:'open',
                        id:Date.now(),
                        item:'Community Amenities',
                        lot:found.id
                    }
                    state.currentType = 'amenities'
                }
            }



        },

        SHAPE_SELECTED:(state,id) => {
            if (state.currentShape !== id)
                state.currentShape = String(id)
        },
        DESELECT_SHAPE:(state) => {
            state.currentShape = ''
        },
        SELECT_LOT_SHAPE: (state,id) => {
            window.editor.selectSpot(id)
            window.editor.redraw()
        },
        CLOSE_MODAL: (state) => {
            state.modal = {
                show:false,
                type:'',
                index:null,
                settings:null,
                title:'',
                identifier:'',
                lot:null,
                text:'',
                editor:null,
                confirm:() => {},
                load:false
            }
        },
        DELETE_LOT: (state,data) => {
        //    deleteProp(state,['lots',data.id])
        //    let index = state.appData.shapes.findIndex(x => x.id == data.id)
        //    state.appData.shapes.splice(index,1)

           let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(data.shapeId))
            state.editorSettings.settings.spots.splice(index,1)
            state.editorSettings.self.redraw()
            return deleteProp(state,['lots',data.id])
        },
        ADD_UPDATE_LOT: (state, {type,data}) => {
            if (type === 'delete'){
                console.log('DELETE SPOTT', data.shapeId)
                let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(data.shapeId))
                state.editorSettings.settings.spots.splice(index,1)
                state.editorSettings.self.redraw()
                return deleteProp(state,['lots',data.id])
            }
            setProp(state,['lots',data.id],data)
            let tags = data.tags
            tags.forEach(tag => {
                let found = state.tags.find(x => x.id === tag.id)
                if (!found) {
                    state.tags.push(tag)
                }
            })
        },
        ADD_UPDATE_AMENITY: (state, {type,data}) => {
            if (type === 'delete'){
                console.log('DELETE SPOTT', data.shapeId)
                let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(data.shapeId))
                state.editorSettings.settings.spots.splice(index,1)
                state.editorSettings.self.redraw()
                return deleteProp(state,['lots',data.id])
            }
            setProp(state,['lots',data.id],data)
            let tags = data.tags
            tags.forEach(tag => {
                let found = state.tags.find(x => x.id === tag.id)
                if (!found) {
                    state.tags.push(tag)
                }
            })
        },
        SHOW_MODAL: (state,{index,settings,editor, type = ''}) => {
            if (type === 'delete'){

                let {id} =  settings.spots[index]
                let lot = Object.values(state.lots).find(x => x.shapeId == id)
                console.log('IDDDD TO DELETE", id', lot)

                state.modal = {
                    type:'delete',
                    show:true,
                    title:'Deleting Lot',
                    text:'Are you sure you want to delete this Spot?',
                    identifier:'',
                    index,
                    lot,
                    settings,
                    editor
                }
            }
        },
        SHOW_ADD_LOT: (state, data)=> {
            state.newLotInfo = data
            state.lotDrawer = {
                show:true,
                type:'add'
            }
        },
        SHOW_ADD_AMENITY: (state, data)=> {
            state.newAmenityInfo = data
            state.amenityDrawer = {
                show:true,
                type:'add'
            }
        },
        SHOW_EDIT_LOT: (state, lot)=> {
            state.editingLot = lot
            state.lotDrawer = {
                show:true,
                type:'edit'
            }
        },
        SHOW_EDIT_AMENITY: (state, lot)=> {
            state.editingAmenity = lot
            state.amenityDrawer = {
                show:true,
                type:'edit'
            }
        },
        CLOSE_AMENITY: (state,{type,data}) => {
            state.newAmenityInfo = null
            state.amenityDrawer = {
                show:false,
                type:'add'
            }
        },
        CLOSE_DRAWER: (state,{type,data}) => {
            state.newLotInfo = null
            state.lotDrawer = {
                show:false,
                type:'add'
            }
        },
        UPDATE_OPTIONS(state, data) {
            state.appData = data
        },
        SET_APPDATA(state, {masterplan = {},tags = [], siteplan, units = [], unitGroups = [], children}) {

            if (masterplan){
                let {lots = [],...obj} = masterplan
                let lotsOBJ = {}

                lots.forEach(x => {
                    x.tags = x.tags.map(y => typeof y === 'string'? y : y.id)
                    lotsOBJ[x.id] = x
                })
                state.lots = lotsOBJ
                state.appData = obj
            }


            if(children && Object.keys(children).length != 0) {
                state.children = children
                Object.values(children).forEach(child => {
                    if (child.siteplan!=null && child.siteplan.lots && child.siteplan.lots.length) {
                        child.siteplan.lots.forEach(lot => {
                            state.childLots[lot.id] = lot
                        })
                    }
                    if (child.unitGroups != null && child.unitGroups.length!=0 && child.unitGroups) {
                        child.unitGroups.forEach(unit => {
                            state.childUnits[unit.id] = unit
                        })
                    }
                })
            }


            state.siteplan = siteplan
            state.unitGroups = unitGroups
            state.units = units
            state.tags = tags

        },
        GET_STARTED: state => {
            state.getStarted = true
        },
    },
    actions: {
        CONFIRM_MODAL: ({commit, state,rootState}) => {
            let {modal} = state
            if (modal.type === 'delete'){

                console.log('DELETING', modal.lot)

                let url = `/lots/${rootState.instance.id}/${state.appData.id}/${modal.lot.id}`
                $api.delete(url).then( ({data}) => {
                    commit('DELETE_LOT', data)
                    let {settings,editor,index} = modal

                    if (settings.editor.selected_shape == settings.spots[index].id) {
                        editor.deselectSpot();
                    }
                    settings.spots.splice(index, 1);
                    editor.redraw();
                    editor.addAction();
                    commit('CLOSE_MODAL')
                })

            }
        },
        UPDATE_SPOT: ({commit,state, rootState}, data) => {
            if (!data) return
            let found = Object.values(state.lots).find(x => x.shapeId == data.id)
            if (!found) return null
            let url = `/siteplans/${rootState.instance.id}/${state.appData.id}/${data.id}`
            console.log('URL', url, data)
            $api.put(url, data)
        },
        CLOSE_DRAWER: ({state,commit},{type,data}) => {
            if (type !== 'click') {
                console.log('STATE NEW LOT INFO', state.newLotInfo)
                if (type === 'add'){
                    if (!state.appData.shapes) state.appData.shapes = []
                    state.appData.shapes.push(state.newLotInfo.point)
                }
                commit('ADD_UPDATE_LOT', {type,data})
            }
            commit('CLOSE_DRAWER', data)
        },
        CLOSE_AMENITY: ({state,commit},{type,data}) => {
            if (type !== 'click') {
                console.log('STATE NEW AMENITY INFO', state.newAmenityInfo)
                if (type === 'add'){
                    if (!state.appData.shapes) state.appData.shapes = []
                    state.appData.shapes.push(state.newAmenityInfo.point)
                }
                commit('ADD_UPDATE_AMENITY', {type,data})
            }
            commit('CLOSE_AMENITY', data)
        },
        ADD_NEW_LOT: async({ state, commit, dispatch }, data) => {
            commit('ADD_NEW_LOT', data)
        },
        SET_APPDATA: async({ state, commit, dispatch }, data) => {
            if (data) {
                commit('SET_APPDATA', data)
            }
        },
    },
    getters: {
        appData: state => state.masterplan, //this.$store.getters['appData']
        childType: state => {
            let types = []
            if (Object.keys(state.childUnits).length != 0) {
                Object.values(state.childUnits).forEach(unit => {
                    let name = `${unit.size}' ${unit.type}`
                    if (!types.includes(name)) {
                        types.push(name)
                    }
                })
            }
            return types
        },
        parentType: state => {
            let types = []
            if (state.unitGroups.length != 0) {
                state.unitGroups.forEach(unit => {
                    let name = `${unit.size}' ${unit.type}`
                    if (!types.includes(name)) {
                        types.push(name)
                    }
                })
            }
            return types
        }
    },
}
