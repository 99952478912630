<template>
    <div v-if="false">
        <div class="menu-left-heading">
            <span>Lots</span>
        </div>

        <div class="Accordion" v-if="Object.keys(lots).length">
            <draggable :list="lots" @end="updateIds" :delay-on-touch-only="true" :delay="100">
                <expander
                    :loading="false"
                    v-for="lot in lots"
                    :key="lot.id"
                    :title="lot.name"
                    animation="bounceIn"
                    @mouseover.native="highlight(lot.shapeId)"
                    @clicked="$store.commit('SELECT_LOT_SHAPE', lot.shapeId)"
                    :show-edit="true"
                    @edit="editLot(lot)"
                    :arrow="false"
                    :open="openID === lot.id"
                    :class="
                        $store.state.masterplan.currentShape === lot.shapeId
                            ? 'text-primary'
                            : ''
                    "
                >
                </expander>
            </draggable>
        </div>
        <div class="p-5 text-center" v-else>
            <h5>You have no lots identified.</h5>
            Please start drawing to get started.
        </div>
    </div>

    <div v-else class="w-full h-full dF fC aS" style="display: inline-block">
        <LeftNavBar
            :selectedValue="$store.state.masterplan.currentType"
            @opened="parentClicked"
            :selectedItem="selectShape"
            class="f1"
            :openNav="openLeftNav"
            :title="selectedAppInitial"
            :imgSrc="'https://bildhive.nyc3.digitaloceanspaces.com/v3/masterplan_82ad348490.svg'"
            :items="lotList"
            :hasChild="true"
            @optionclick="moreOptions"
            @chosen="selectLot"
        >
        </LeftNavBar>
    </div>
</template>

<script>
import Expander from "bh-mod/components/common/Expander";
import vueCustomScrollbar from "vue-custom-scrollbar";
import draggable from "vuedraggable";
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: { Expander, vueCustomScrollbar, draggable, LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
            lots: [],
            hightlight: false,
            deleteObj: {},
        };
    },
    watch: {
        Zlots(val) {
            this.lots = val.sort((a, b) => {
                if (a.order > b.order) return 1;
                return -1;
            });
        },
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "MPC"
            );
        },
        childLots() {
            return this.$store.state.masterplan.childLots;
        },
        selectShape() {
            return this.$store.state.masterplan.openLeftNav.lot;
        },
        openLeftNav() {
            return this.$store.state.masterplan.openLeftNav;
        },
        lotList() {
            console.log("zlot", this.Zlots);
            let list = [
                {
                    label: "Master Plan Lots",
                    value: "lots",
                    loading: false,
                    options: [],
                    children: [],
                    iconUrl: require("@/assets/lot-icon.svg"),
                },
                {
                    label: "Community Amenities",
                    value: "amenities",
                    options: [],
                    loading: false,
                    children: [],
                    iconUrl: require("@/assets/amenity-icon.svg"),
                },
            ];
            console.log("ASKTJKASTAS", this.siteplanLots);

            this.siteplanLots.forEach((lot) => {
                let obj = {
                    label: "",
                    value: "",
                    parent: "",
                    type: "",
                    options: [],
                };
                if (lot.type == "info") {
                    obj.label = lot.name;
                    obj.value = lot.id;
                    obj.type = lot.type;

                    if (lot.hasOwnProperty("inherit") && lot.inherit) {
                        let inherit = lot.inherit;
                        if (inherit.id) inherit = inherit.id;

                        if (
                            this.childLots[inherit] &&
                            this.childLots[inherit].name
                        )
                            obj.label = this.childLots[inherit].name;
                    }
                    obj.parent = "amenities";
                    obj.options = [
                        { label: "Edit Amenity", value: "edit" },
                        { label: "Delete Amenity", value: "delete" },
                    ];
                    list[1].children.push(obj);
                }
                if (lot.type == "lot") {
                    if (lot.inherit) {
                        let inherit = lot.inherit;
                        if (inherit.id) inherit = inherit.id;

                        if (
                            this.childLots[inherit] &&
                            this.childLots[inherit].name
                        ) {
                            obj.label = this.childLots[inherit].name;
                            obj.value = lot.id;
                            obj.type = lot.type;
                            obj.parent = "lots";
                            obj.options = [
                                { label: "Edit Lot", value: "edit" },
                                { label: "Delete Lot", value: "delete" },
                            ];

                            list[0].children.push(obj);
                        }
                    }
                }
            });

            list[0].children.sort((a, b) => a.label - b.label);
            list[1].children.sort((a, b) => {
                if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                return 0;
            });

            console.log("listtt", list);
            return list;
        },
        storeLots() {
            return this.$store.state.masterplan.lots;
        },
        Zlots() {
            return Object.values(this.$store.state.masterplan.lots);
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
        siteplanLots() {
            return Object.values(this.$store.state.masterplan.lots);
        },
        instance() {
            return this.$store.state.instance;
        },
        appData() {
            return this.$store.state.masterplan.appData;
        },
    },
    methods: {
        parentClicked(e) {
            console.log("eee", e);
            this.$store.commit("changeType", e.value);
        },
        selectLot(data) {
            console.log("lott", data);
            let selectedLot = {};
            for (var i = 0; i < this.siteplanLots.length; i++) {
                if (data.value == this.siteplanLots[i].id) {
                    selectedLot = this.siteplanLots[i];
                }
            }
            console.log("selectedLot", selectedLot, selectedLot.name);
            if (selectedLot.name && selectedLot.name != "") {
                console.log("run that");
                this.$store.commit("SET_CRUMBS", selectedLot.name);
            } else if (
                selectedLot.inherit &&
                selectedLot.inherit != "" &&
                this.childLots[selectedLot.inherit] &&
                this.childLots[selectedLot.inherit].name
            ) {
                this.$store.commit(
                    "SET_CRUMBS",
                    this.childLots[selectedLot.inherit].name
                );
                console.log(
                    "run this",
                    this.childLots[selectedLot.inherit].name
                );
            }
            this.$store.commit("SELECT_LOT_SHAPE", selectedLot.shapeId);
        },
        moreOptions(data) {
            console.log(data);
            let editLot = {};
            if (data.optionValue == "edit") {
                for (var i in this.siteplanLots) {
                    if (this.siteplanLots[i].id == data.itemValue) {
                        editLot = this.siteplanLots[i];
                    }
                }
                if (data.parent == "lots") {
                    this.$store.commit("SHOW_EDIT_LOT", editLot);
                } else if (data.parent == "amenities") {
                    this.$store.commit("SHOW_EDIT_AMENITY", editLot);
                }
            }
            if (data.optionValue == "delete") {
                for (var i in this.siteplanLots) {
                    if (this.siteplanLots[i].id == data.itemValue) {
                        this.deleteObj = this.siteplanLots[i];
                    }
                }
                if (this.$p < 40)
                    return this.$message.error(
                        "You do not have permission to delete this lot"
                    );
                let self = this;
                this.$confirm({
                    title: "Delete",
                    content: (h) => (
                        <div>
                            Do you want to delete this Site Plan Lot. Deleting
                            will permanently remove the Site Plan Lot
                        </div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        self.confirmDelete();
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        confirmDelete() {
            console.log("confirmdelete");
            console.log("deleteObj", this.deleteObj);
            this.$api
                .delete(
                    `/lots/${this.instance.id}/${this.appData.id}/${this.deleteObj.id}`
                )
                .then(({ data }) => {
                    this.$store.commit("DELETE_LOT", data);
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        showtype() {
            console.log("typeeeee", this.siteplanLots);
        },
        highlight(id) {
            console.log("HIGHLIGHTING ID", id);
            document
                .querySelectorAll(".lot-highlight")
                .forEach((x) => x.classList.remove("lot-highlight"));
            if (
                document.querySelector(
                    `.imp-editor-shape.imp-editor-poly[data-id="${id}"]`
                )
            ) {
                document
                    .querySelector(
                        `.imp-editor-shape.imp-editor-poly[data-id="${id}"]`
                    )
                    .classList.add("lot-highlight");
            }
        },
        updateIds() {
            let ids = this.lots.map((x) => x.id);
            this.$api
                .put(
                    `/lots/${this.$store.state.instance.id}/${this.$store.state.masterplan.appData.id}/arrange-ids`,
                    ids
                )
                .then(() => {
                    this.$store.commit("REORDER_LOTS", ids);
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        editLot(lot) {
            console.log("EDITING LOT", lot);
            this.$store.commit("SHOW_EDIT_LOT", lot);
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
    created() {
        this.lots = Object.values(this.$store.state.masterplan.lots).sort(
            (a, b) => {
                if (a.order > b.order) return 1;
                return -1;
            }
        );
    },
};
</script>
