<template>
    <div>
        <div :class="$style.subbar" v-if="instance" class="mt-3">
            <div class="dF aC mb-0">
                <ul v-if="false" :class="$style.breadcrumbs" class="mr-4">
                    <li :class="$style.breadcrumb" class="text-dark-purple">
                        {{ instance.name }}
                    </li>
                </ul>
                <a-breadcrumb>
                    <a-breadcrumb-item>{{ instance.name }}</a-breadcrumb-item>
                    <a-breadcrumb-item>{{
                        selectedAppInitial
                    }}</a-breadcrumb-item>
                    <a-breadcrumb-item
                        v-if="
                            this.$store.state.masterplan.crumbs &&
                            this.$store.state.masterplan.crumbs != ''
                        "
                        style="color: var(--orange)"
                        >{{
                            this.$store.state.masterplan.crumbs
                        }}</a-breadcrumb-item
                    >
                </a-breadcrumb>

                <div :class="$style.amount" class="ml-auto d-none d-sm-flex">
                    <button
                        @click="$store.commit('COLORS_SETTINGS')"
                        type="button"
                        class="btn btn-primary mr-3"
                    >
                        COLORS
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "MPC"
            );
        },
        instance() {
            return this.$store.state.instance;
        },
        hasChildren() {
            if (
                Object.keys(this.$store.state.masterplan.children).length !=
                    0 &&
                Object.keys(this.$store.state.masterplan.childLots).length != 0
            )
                return true;
            else return false;
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
